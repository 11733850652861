<template>
  <div class="flex-grow-1">
    <UserTitle>
      <template v-slot:actions>
        <preloader v-if="!school && status.getting" />
        <div class="d-flex align-center justify-space-between full-width" v-if="school">
          <h2 class="font-weight-black text-capitalize" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">
            {{ school.name }}
          </h2>
          
          <div v-if="$store.getters['schools/isOwner'](school)">
            <v-btn 
              :to="{ name: 'School', query: { id: school.id } }"
              color="accent gradient"
            >
              Edit
            </v-btn>
          </div>
        </div>
      </template>
    </UserTitle>

    <!-- Filters -->
    <div v-if="school">
      <div v-if="!status.getting && Object.keys(school).length">
      <v-row>
        <v-col cols="12">
          <v-row class="mb-5" no-gutters>
            <v-col :cols="$vuetify.breakpoint.xs ? '6' : ''">
              <school-logo :filename="school.logo" />
            </v-col>
          </v-row>

          <h5 class="text-h5 font-weight-black mb-3">{{ school.name }}</h5>
          <div class="d-flex flex-column">
            <div v-if="school.address" class="mb-2">
              <span>
                <a :href="`https://www.google.com/maps/search/?api=1&query=${school.address.latitude},${school.address.longitude}`" class="text-decoration-none underline-on-hover" target="_blank">
                  {{ [`${school.address.street_number || '' } ${school.address.route || ''}`, school.address.locality, school.address.administrative_area_level_1].join(', ') }}
                </a>
              </span>
            </div>

            <div v-if="school.website" class="mb-2">
              <a
                class="text-decoration-none underline-on-hover info--text"
                :href="`${school.website}`" 
                target="_blank"
              >
                {{ school.website }}
              </a>
            </div>

            <div class="mb-2">{{ school.type }}</div>
            <div>{{ school.locale }}</div>

            <!-- about me section -->
            <div class="d-flex flex-column mt-4">
              <div class="mt-4 mb-9 text-pre-line">
                {{ school.introduction }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    </div>
    <!-- End Filters -->

    <!-- CONFIRM DELETE -->
    <ConfirmDelete
      :show="showDialog"
      message="Are you sure you want to delete this school?"
      @confirmed="deleteConfirmed()"
      :deleting="status.deleting"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "animate.css";
import SchoolLogo from './SchoolLogo'

export default {
  name: "SchoolsProfile",

  metaInfo: {
    title: "School Profile",
  },

  data() {
    return {
      showDialog: false,
      toDelete: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    SchoolLogo
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      school: (state) => state.schools.selectedSchool,
      queries: (state) => state.schools.queries,
      schoolLogo: (state) => state.schools.selectedSchoolLogo,
      status: (state) => state.schools.status,
      user: (state) => state.user.user,
    }),
  },

  watch: {
    'queries':function(val) {
      if(val.length){
        val.forEach( query => { 
          this.getUserByUid(query.talentId)
        })
      }
    }
  },

  methods: {
    ...mapActions('schools', [
      'getSelectedSchoolById', 
      'deleteSchool',
    ]),
    
    ...mapActions('users', [
      'getUserByUid', 
    ]),

    confirmDelete(school) {
      this.toDelete = school
      this.showDialog = true
    },

    closeDialog() {
      this.toDelete = null
      this.showDialog = false
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteSchool(this.toDelete)
      ])
      .then(() => {
        this.$router.push({ name: 'Schools' })
      })
    },

    getUsers() {
      this.queries.forEach( query => {
        this.getUserByUid(query.talentId)
      })
    }

  },

  mounted() {
    this.getUsers()
  },

  created() {
    if (!this.school) {
      this.getSelectedSchoolById(this.$route.params.id);
    } 
    else {
      if (this.school.id != this.$route.params.id) {
        this.getSelectedSchoolById(this.$route.params.id);
      }
    }
  },
};
</script>